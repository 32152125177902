<template>
  <NewProduct></NewProduct>
</template>

<script>
import NewProduct from "../components/NewProduct";
export default {
  components: { NewProduct },
};
</script>
