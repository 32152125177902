<template>
  <div>
    <component
      :is="active.newProductComponent"
      @click:type="SET_TYPE"
    ></component>
  </div>
</template>

<script>
import Types from "../Type/Types.js";
export default {
  components: {
    SelectType: () => import("./SelectType.vue"),
    NewCourse: () => import("./NewCourse.vue"),
    NewEBook: () => import("./NewEBook.vue"),
    NewEvent: () => import("./NewEvent.vue"),
    NewSeance: () => import("./NewSeance.vue"),
    NewBlog: () => import("./NewBlog.vue"),
  },
  mixins: [Types],
  data() {
    return {
      active: {
        type: 0,
        title: "Select Type",
        domain: "",
        id: "select-type",
        newProductComponent: "SelectType",
      },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_FIELDS_BY_TYPE();
      },
    },
  },
  methods: {
    SET_TYPE(field) {
      this.$router.push({ query: { type: field.id } });
    },
    SET_FIELDS_BY_TYPE() {
      if (this.$route.query.type) {
        const field = this.Types.find((t) => t.id === this.$route.query.type);
        if (field) this.active = field;
      } else
        this.active = {
          type: 0,
          title: "Select Type",
          domain: "",
          id: "select-type",
          newProductComponent: "SelectType",
        };
    },
  },
  created() {
    this.SET_FIELDS_BY_TYPE();
  },
};
</script>
